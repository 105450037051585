import React, { useEffect, useState } from 'react';
import { Endpoints } from '../../api/endpoints';
import { ACCESS_TOKEN_NAME } from '../../constants';
import axios from 'axios';
import classes from './ClaimsPage.module.css';

const ClaimsPage = () => {
  const [claimList, setClimeList] = useState([]);

  const [showNotSolved, setShowNotSolved] = useState(false);

  const [usersList, setUsersList] = useState([]);
  const [allResponsableUsers, setAllResponsableUsers] = useState([]);
  const [selectedClaim, setSelectedClime] = useState({});
  const [selectedClaimUser, setSelectedClimeUser] = useState('');

  const [climeText, setClimeText] = useState('');

  useEffect(() => {
    updateUsersList();
    updateClimeList();

    let intervalId = setInterval(() => {
      updateUsersList();
      updateClimeList();
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [showNotSolved]);

  useEffect(() => {
    if (selectedClaim.id === undefined && claimList.length != 0) {
      setSelectedClime(claimList[0]);
    } else {
      claimList.forEach(claim => {
        if (claim.id == selectedClaim.id) {
          setSelectedClime(claim);
        }
      });
    }
  }, [claimList]);

  useEffect(() => {
    if (selectedClaim.id === undefined || selectedClaimUser === '') {
      usersList.forEach(user => {
        if (user.id == selectedClaim.userId) setSelectedClimeUser(user.fullName);
      });
    }
  }, [usersList]);

  useEffect(() => {
    // console.log(selectedClaim)
    usersList.forEach(user => {
      if (user.id == selectedClaim.userId) setSelectedClimeUser(user.fullName);
    });
    if (selectedClaim.id != undefined) getClime();
  }, [selectedClaim]);

  useEffect(() => {
    updateClimeList();
  }, [showNotSolved]);

  const updateClimeList = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };

    let url = '';
    if (showNotSolved) {
      url = Endpoints.API.GET_NOT_SOLVED_CLIMES;
    } else {
      url = Endpoints.API.GET_ALL_CLIMES;
    }
    console.log(showNotSolved);
    console.log(url);

    axios
      .get(url, config)
      .then(res => {
        let data = res.data;

        console.log(data);
        setClimeList(
          data.sort((a, b) => {
            return b.id - a.id;
          }),
        );
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateUsersList = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };

    axios
      .get(Endpoints.API.ALL_USERS, config)
      .then(res => {
        let data = res.data;
        // console.log(data)
        setUsersList(data);

        let respUsersArr = [];
        respUsersArr.push({ fullName: 'Не выбран', id: 0 });
        data.forEach(user => {
          if (user.roleEntity.id <= 2) {
            respUsersArr.push({ fullName: user.fullName, id: user.id });
          }
        });
        // console.log(respUsersArr)
        setAllResponsableUsers(respUsersArr);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const setResponsibleUser = responsibleUserId => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json;charset=utf-8',
      },
    };

    const payload = {
      someId: responsibleUserId,
    };

    axios
      .put(Endpoints.API.PUT_CLIME_MANAGER + selectedClaim.id, JSON.stringify(payload), config)
      .then(res => {
        console.log('set responsible manager');
        // console.log(selectedClaim)
        updateClimeList();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const setAsSolved = climeId => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };

    axios
      .get(Endpoints.API.GET_MARK_CLIME_AS_SOLVED + climeId, config)
      .then(res => {
        updateClimeList();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getClime = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };

    axios
      .get(Endpoints.API.GET_CLIME + selectedClaim.id, config)
      .then(res => {
        let data = res.data;
        // console.log(data)
        setClimeText(data.claimText);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className={classes.claims}>
      <h1 className={classes.header}>Обращения пользователей</h1>

      <div className={classes.mainContainer}>
        <div className={classes.claimInfo}>
          <div>
            <h2>{selectedClaim.type}</h2>
            <h4>Время: {new Date(selectedClaim.timeDate).toLocaleString()}</h4>
            <h4 style={{ display: 'inline-block', marginRight: 10 }}>Пользователь: </h4>
            <label>{selectedClaimUser}</label>
            <br />
            <h4 style={{ display: 'inline-block', marginRight: 10 }}>Ответственный: </h4>
            <select value={selectedClaim.responsibleUserId} onChange={e => setResponsibleUser(e.target.value)}>
              {allResponsableUsers.map(user => (
                <option key={user.id} value={user.id}>
                  {user.fullName}
                </option>
              ))}
            </select>
            <p>Текст обращения: </p>
            <p className={classes.text}>{climeText}</p>
          </div>
        </div>

        <div className={classes.claimList}>
          <label>
            <input
              type="checkbox"
              checked={showNotSolved}
              onChange={e => {
                setShowNotSolved(e.target.checked);
              }}
            />{' '}
            Только необработаные
          </label>
          <ul>
            {claimList.map(claim => (
              <div
                key={claim.id}
                className={selectedClaim?.id == claim.id ? classes.claimSelected + ' ' + classes.claim : classes.claim}
                onClick={e => {
                  setSelectedClime(claim);
                }}
              >
                <div className={classes.claimLeft}>
                  <h2>{claim.type}</h2>
                  <h4>Время: {new Date(claim.timeDate).toLocaleString()}</h4>
                  <h4>Пользователь: {claim.userId}</h4>
                </div>
                <div className={classes.claimRight}>
                  <label>
                    <input
                      type="checkbox"
                      disabled={claim.isSolved}
                      checked={claim.isSolved}
                      onChange={e => {
                        setAsSolved(claim.id);
                      }}
                    />{' '}
                    решён
                  </label>
                  <h4>Ответственный: {claim.responsibleUserId}</h4>
                  <button>Удалить</button>
                </div>
              </div>
            ))}
            <li></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ClaimsPage;
