import { React, useState, useEffect } from 'react';

import ReactDatePicker from 'react-datepicker';
import { ACCESS_TOKEN_NAME } from '../constants';
import { Endpoints } from '../api/endpoints';
import classes from '../css/statistics.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const Statistics = () => {
  let dateFrom = new Date();
  dateFrom.setDate(dateFrom.getDate() - 7);

  const defaultFilteerProps = {
    useDate: true,
    useMaterial: false,
    useCompany: false,
    useCertainPlaceAddress: false,
    useEventTypes: false,
    useMaterialPrice: false,
    useAddPrice: false,
    useAllPrice: false,
    dateFrom: dateFrom,
    dateTo: new Date(),
    material: '',
    company: '',
    certainPlaceAddress: '',
    eventTypes: '',
    materialPriceFrom: '',
    materialPriceTo: '',
    addPriceFrom: '',
    addPriceTo: '',
    allPriceFrom: '',
    allPriceTo: '',
  };

  const [eventsList, setEventsList] = useState([]);
  const [availableMaterials, setAvailableMaterials] = useState([]);
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [availableEventTypes, setAvailableEventTypes] = useState([]);

  // const [useFilter, setUseFilter] = useState(true)
  const [update, setUpdate] = useState(false);

  const [filterProps, setFilterProps] = useState(defaultFilteerProps);

  useEffect(() => {
    updateAllEventsList();
    updateEventsList();

    let intervalId = setInterval(() => {
      // updateAllEventsList()
      updateAllEventsList();
      updateEventsList();
    }, 10000);

    return () => {
      clearInterval(intervalId);
      // console.log("clear updateUsersList")
    };
  }, [update]);

  const updateAllEventsList = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json;charset=utf-8',
      },
    };

    let filterObject = {
      dateFrom: filterProps.useDate ? filterProps.dateFrom.toISOString() : '',
      dateTo: filterProps.useDate ? filterProps.dateTo.toISOString() : '',
      companyName: '',
      certainPlaceAddress: '',
      materialPriceFrom: -1,
      materialPriceTo: -1,
      addPriceFrom: -1,
      addPriceTo: -1,
      allPriceFrom: -1,
      allPriceTo: -1,
      materials: '',
      type: '',
    };

    axios
      .post(Endpoints.API.FILTERED_CALC_EVENTS, JSON.stringify(filterObject), config)
      .then(res => {
        let materials = [];
        res.data.forEach(element => {
          let materialsArr = element.materials.split(',');
          // console.log(materialsArr)
          materialsArr.forEach(material => {
            if (material !== '' && materials.indexOf(material) === -1) materials.push(material);
          });
        });
        materials.sort();
        setAvailableMaterials(materials);

        let companies = [];
        res.data.forEach(element => {
          if (companies.indexOf(element.companyName) === -1) companies.push(element.companyName);
        });
        companies.sort();
        setAvailableCompanies(companies);

        let eventTypes = [];
        res.data.forEach(element => {
          if (eventTypes.indexOf(element.type) === -1) eventTypes.push(element.type);
        });
        eventTypes.sort();
        setAvailableEventTypes(eventTypes);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateEventsList = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json;charset=utf-8',
      },
    };

    let filterObject = {
      dateFrom: filterProps.useDate ? filterProps.dateFrom.toISOString() : '',
      dateTo: filterProps.useDate ? filterProps.dateTo.toISOString() : '',
      companyName: filterProps.useCompany ? filterProps.company : '',
      certainPlaceAddress: filterProps.useCertainPlaceAddress ? filterProps.certainPlaceAddress : '',
      materialPriceFrom: filterProps.useMaterialPrice ? filterProps.materialPriceFrom : -1,
      materialPriceTo: filterProps.useMaterialPrice ? filterProps.materialPriceTo : -1,
      addPriceFrom: filterProps.useAddPrice ? filterProps.addPriceFrom : -1,
      addPriceTo: filterProps.useAddPrice ? filterProps.addPriceTo : -1,
      allPriceFrom: filterProps.useAllPrice ? filterProps.allPriceFrom : -1,
      allPriceTo: filterProps.useAllPrice ? filterProps.allPriceTo : -1,
      materials: filterProps.useMaterial ? filterProps.material : '',
      type: filterProps.useEventTypes ? filterProps.eventTypes : '',
    };

    // console.log(filterObject)

    axios
      .post(Endpoints.API.FILTERED_CALC_EVENTS, JSON.stringify(filterObject), config)
      .then(res => {
        let data = res.data;

        console.log(res.data);
        setEventsList(
          data.sort((a, b) => {
            let aTime = new Date(a.activityTime);
            let bTime = new Date(b.activityTime);

            return bTime.getTime() - aTime.getTime();
          }),
        );

        // let materialsSet = new Set()
        // res.data.forEach(element => {
        //     let materialsArr = element.materials.split(",")
        //     materialsArr.forEach(material =>{
        //         if(material !== "")materialsSet.add(material)
        //     })
        // });

        // setAvailableMaterials(Array.from(materialsSet))
        // console.log(materialsSet)
      })
      .catch(error => {
        console.log(error);
      });
  };

  const downloadXlsFile = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json;charset=utf-8',
      },
    };

    let filterObject = {
      dateFrom: filterProps.useDate ? filterProps.dateFrom.toISOString() : '',
      dateTo: filterProps.useDate ? filterProps.dateTo.toISOString() : '',
      companyName: filterProps.useCompany ? filterProps.company : '',
      certainPlaceAddress: filterProps.useCertainPlaceAddress ? filterProps.certainPlaceAddress : '',
      materialPriceFrom: filterProps.useMaterialPrice ? filterProps.materialPriceFrom : -1,
      materialPriceTo: filterProps.useMaterialPrice ? filterProps.materialPriceTo : -1,
      addPriceFrom: filterProps.useAddPrice ? filterProps.addPriceFrom : -1,
      addPriceTo: filterProps.useAddPrice ? filterProps.addPriceTo : -1,
      allPriceFrom: filterProps.useAllPrice ? filterProps.allPriceFrom : -1,
      allPriceTo: filterProps.useAllPrice ? filterProps.allPriceTo : -1,
      materials: filterProps.useMaterial ? filterProps.material : '',
      type: filterProps.useEventTypes ? filterProps.eventTypes : '',
    };

    axios
      .post(Endpoints.API.FILTERED_CALC_EVENTS_FILE, JSON.stringify(filterObject), config)
      .then(res => {
        // console.log(res.data)
        // setEventsList(data)

        axios({
          url: res.data.fileDownloadUri, //your url
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json;charset=utf-8',
          },
          responseType: 'blob', // important
          data: filterObject,
        }).then(response => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', res.data.fileName); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const setFilterPreset = preset => {
    if (preset === 0) {
    } else if (preset === 1) {
    } else if (preset === 2) {
    } else if (preset === 3) {
      setFilterProps(filterProps => ({
        ...filterProps,
        ...{ useMaterialPrice: true, materialPriceFrom: 100, materialPriceTo: 1000 },
      }));
    }
  };

  return (
    <>
      <h2 className={classes.header}>Статистика</h2>
      <div className={classes.container_statistics}>
        <div className={classes.container_inner}>
          <div className={classes.filter_onoff}>
            <div>
              {/* <input id="filter_use" type="checkbox" checked={useFilter} onChange={e=>{setUseFilter(e.target.checked)}}/> */}
              <span>Фильтровать:</span>
            </div>
            <div>
              <input type="button" value="Download .xls " onClick={() => downloadXlsFile()} />
            </div>
          </div>

          <div className={classes.preset_buttons}>
            <input
              type="button"
              value="Фильтр 1"
              onClick={e => {
                setFilterPreset(0);
              }}
            />
            <input
              type="button"
              value="Фильтр 2"
              onClick={e => {
                setFilterPreset(1);
              }}
            />
            <input
              type="button"
              value="Фильтр 3"
              onClick={e => {
                setFilterPreset(2);
              }}
            />
            <input
              type="button"
              value="Фильтр 4"
              onClick={e => {
                setFilterPreset(3);
              }}
            />
          </div>

          <form
            onSubmit={e => {
              e.preventDefault();
              setUpdate(!update);
            }}
          >
            <div className={classes.container_filter}>
              <div className={classes.filter}>
                <div className={classes.filter_column1}>
                  <div className={classes.filter_row}>
                    <input
                      id="date_use"
                      type="checkbox"
                      checked={filterProps.useDate}
                      onChange={e => {
                        setFilterProps(filterProps => ({ ...filterProps, ...{ useDate: e.target.checked } }));
                      }}
                    />
                    <span>Дата</span>
                    <div>
                      <ReactDatePicker
                        className={classes.datepicker}
                        id="date_from"
                        type="date"
                        dateFormat="dd/MM/yyyy"
                        selected={filterProps.dateFrom}
                        onChange={date => {
                          setFilterProps(filterProps => ({ ...filterProps, ...{ dateFrom: date, useDate: true } }));
                        }}
                      />
                    </div>
                    <span>-</span>
                    <div>
                      <ReactDatePicker
                        className={classes.datepicker}
                        id="date_to"
                        type="date"
                        dateFormat="dd/MM/yyyy"
                        selected={filterProps.dateTo}
                        onChange={date => {
                          setFilterProps(filterProps => ({ ...filterProps, ...{ dateTo: date, useDate: true } }));
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.filter_row}>
                    <input
                      id="material_use"
                      type="checkbox"
                      checked={filterProps.useMaterial}
                      onChange={e => {
                        setFilterProps(filterProps => ({ ...filterProps, ...{ useMaterial: e.target.checked } }));
                      }}
                    />
                    <span>Камень</span>

                    <select
                      className={classes.my_select}
                      value={filterProps.material}
                      onChange={e => {
                        console.log(e.target.value);
                        setFilterProps(filterProps => ({
                          ...filterProps,
                          ...{ material: e.target.value, useMaterial: !(e.target.value === '') },
                        }));
                      }}
                    >
                      {availableMaterials.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>

                    {/* <select 
                                        className={classes.my_select} 
                                        multiple 
                                        // value={filterProps.material} 
                                        onChange={e =>  console.log(e.target.querySelectorAll())}>
                                            {availableMaterials.map((item, index) => 
                                            <option 
                                            key={index} 
                                            value={item}>
                                                {item}
                                            </option>)}
                                        </select> */}
                  </div>
                  <div className={classes.filter_row}>
                    <input
                      id="shop_use"
                      type="checkbox"
                      checked={filterProps.useCompany}
                      onChange={e => {
                        setFilterProps(filterProps => ({ ...filterProps, ...{ useCompany: e.target.checked } }));
                      }}
                    />
                    <span>Салон</span>

                    <select
                      className={classes.my_select}
                      value={filterProps.company}
                      onChange={e => {
                        setFilterProps(filterProps => ({
                          ...filterProps,
                          ...{ company: e.target.value, useCompany: !(e.target.value === '') },
                        }));
                      }}
                    >
                      {availableCompanies.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.filter_row}>
                    <input
                      id="shop_use"
                      type="checkbox"
                      checked={filterProps.useEventTypes}
                      onChange={e => {
                        setFilterProps(filterProps => ({ ...filterProps, ...{ useEventTypes: e.target.checked } }));
                      }}
                    />
                    <span>События</span>

                    <select
                      className={classes.my_select}
                      value={filterProps.eventTypes}
                      onChange={e => {
                        setFilterProps(filterProps => ({
                          ...filterProps,
                          ...{ eventTypes: e.target.value, useEventTypes: !(e.target.value === '') },
                        }));
                      }}
                    >
                      {availableEventTypes.map((item, index) => (
                        <option key={index} value={item}>
                          {item === 'show receipt' ? 'Расчет' : item === 'printing' ? 'Печать' : 'Неизвестно'}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes.filter_column2}>
                  <div className={classes.filter_row}>
                    <input
                      id="material_price_use"
                      type="checkbox"
                      checked={filterProps.useMaterialPrice}
                      onChange={e => {
                        setFilterProps(filterProps => ({ ...filterProps, ...{ useMaterialPrice: e.target.checked } }));
                      }}
                    />
                    <span>Цена материала</span>
                    <span>от</span>
                    <input
                      id="materialPriceFrom"
                      pattern="[0-9]*"
                      value={filterProps.materialPriceFrom}
                      onChange={e => {
                        setFilterProps(filterProps => ({
                          ...filterProps,
                          ...{ materialPriceFrom: e.target.value, useMaterialPrice: !(e.target.value === '') },
                        }));
                      }}
                    />
                    <span>до</span>
                    <input
                      id="materialPriceTo"
                      pattern="[0-9]*"
                      value={filterProps.materialPriceTo}
                      onChange={e => {
                        setFilterProps(filterProps => ({
                          ...filterProps,
                          ...{ materialPriceTo: e.target.value, useMaterialPrice: !(e.target.value === '') },
                        }));
                      }}
                    />
                    <span>руб.</span>
                  </div>
                  <div className={classes.filter_row}>
                    <input
                      id="add_price_use"
                      type="checkbox"
                      checked={filterProps.useAddPrice}
                      onChange={e => {
                        setFilterProps(filterProps => ({ ...filterProps, ...{ useAddPrice: e.target.checked } }));
                      }}
                    />
                    <span>Цена доп. работ</span>
                    <span>от</span>
                    <input
                      id="addPriceFrom"
                      pattern="[0-9]*"
                      value={filterProps.addPriceFrom}
                      onChange={e => {
                        setFilterProps(filterProps => ({
                          ...filterProps,
                          ...{ addPriceFrom: e.target.value, useAddPrice: !(e.target.value === '') },
                        }));
                      }}
                    />
                    <span>до</span>
                    <input
                      id="addPriceTo"
                      pattern="[0-9]*"
                      value={filterProps.addPriceTo}
                      onChange={e => {
                        setFilterProps(filterProps => ({
                          ...filterProps,
                          ...{ addPriceTo: e.target.value, useAddPrice: !(e.target.value === '') },
                        }));
                      }}
                    />
                    <span>руб.</span>
                  </div>
                  <div className={classes.filter_row}>
                    <input
                      id="all_use"
                      type="checkbox"
                      checked={filterProps.useAllPrice}
                      onChange={e => {
                        setFilterProps(filterProps => ({ ...filterProps, ...{ useAllPrice: e.target.checked } }));
                      }}
                    />
                    <span>Цена общая</span>
                    <span>от</span>
                    <input
                      id="allPriceFrom"
                      pattern="[0-9]*"
                      value={filterProps.allPriceFrom}
                      onChange={e => {
                        setFilterProps(filterProps => ({
                          ...filterProps,
                          ...{ allPriceFrom: e.target.value, useAllPrice: !(e.target.value === '') },
                        }));
                      }}
                    />
                    <span>до</span>
                    <input
                      id="allPriceTo"
                      pattern="[0-9]*"
                      value={filterProps.allPriceTo}
                      onChange={e => {
                        setFilterProps(filterProps => ({
                          ...filterProps,
                          ...{ allPriceTo: e.target.value, useAllPrice: !(e.target.value === '') },
                        }));
                      }}
                    />
                    <span> руб.</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.updateBtnContainer}>
              <input id="updateButton" type="submit" value="Обновить" />
            </div>
          </form>
          <div className={classes.table_container_statistics}>
            <table className={classes.table_statistics}>
              <colgroup>
                <col style={{ width: '7%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '6%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '29%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '8%' }} />
              </colgroup>

              <thead>
                <tr>
                  <th>Дата</th>
                  <th>Салон</th>
                  <th>Пользователь</th>
                  <th>Тип события</th>
                  <th>Материал (тип-коллекция-цвет)</th>
                  <th>Кол-во слэбов</th>
                  <th>Площадь изделия (м2)</th>
                  <th>Цена материала (₽)</th>
                  <th>Цена доп. работ (₽)</th>
                  <th>Цена счета (₽)</th>
                </tr>
              </thead>

              <tbody>
                {eventsList.map((item, index) => (
                  <tr key={index}>
                    <td>{new Date(item.activityTime).toLocaleString()}</td>
                    <td>{item.companyName}</td>
                    <td>{item.userLogin}</td>
                    <td>
                      {item.type === 'show receipt' ? 'Расчет' : item.type === 'printing' ? 'Печать' : 'Неизвестно'}
                    </td>
                    <td>{item.materials}</td>
                    <td>{item.slabs.toFixed(2)}</td>
                    <td>{item.productSquare.toFixed(2)}</td>
                    <td>{item.materialPrice.toFixed(2)}</td>
                    <td>{item.addPrice.toFixed(2)}</td>
                    <td>{item.allPrice.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
