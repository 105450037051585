import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { ACCESS_TOKEN_NAME } from '../../constants';
import { Endpoints } from '../../api/endpoints';
import style from './MyUsersPage.module.css';
import userLogoImg from '../../images/user_no_logo.png';
import Input from '../UI/myInput/Input';
import Select from '../UI/mySelect/Select';
import AppSelector from '../UI/appSelector/AppSelector';
import { useAuth } from '../../hook/useAuth';

const MyUsersPage = () => {
  const { user } = useAuth();

  const [usersList, setUsersList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(-1);
  const [selectedUserInfo, setSelectedUserInfo] = useState({
    address: 'Москва Кремль',
    appAccess: ',k,z',
    certainPlaceAddress: 'Москва Кремль',
    companyName: 'Гугл',
    email: 'designer11@mail.ru',
    enabled: true,
    fullName: 'Дизайнер от бога №1',
    id: 3,
    keyManagerId: 0,
    login: 'designer1',
    phoneNumber: '+7001333',
    roleEntity: { id: 4, name: 'ROLE_KEYMANAGER' },
  });

  const [userEnable, setUserEnable] = useState(true);
  const [userRole, setUserRole] = useState('');
  const [userLogin, setUserLogin] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userKeyManager, setUserKeyManager] = useState(0);
  const [userCompany, setUserCompany] = useState('');
  const [userCompanyId, setUserCompanyId] = useState(0);
  const [userAddress, setUserAddress] = useState('');
  const [userCertainAddress, setUserCertainAddress] = useState('');
  const [userPhone, setUserPhone] = useState('');

  const [userAppAccess, setUserAppAccess] = useState('');

  const [noKeyManagerCounter, setNoKeyManagerCounter] = useState(false);

  const [filterNoKeyManager, setfilterNoKeyManager] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterCompanyId, setFilterCompanyId] = useState(0);
  const [filterKeyManager, setFilterKeyManager] = useState('');
  const [filterRole, setFilterRole] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [leftContainer, setLeftContainer] = useState(<></>);
  const [showCreateUserView, setShowCreateUserView] = useState(true);

  const [allCompanies, setAllCompanies] = useState([]);
  const [allKeyManagers, setAllKeyManagers] = useState([]);

  const [disableEditingUsers, setDisabledEditingUsers] = useState(user.role === 'ROLE_KEYMANAGER');

  let newUserDefault = {
    password: '',
    login: '',
    email: '',
    fullName: '',
    companyName: '',
    phoneNumber: '',
    address: '',
    certainPlaceAddress: '',
    appAccess: 'k',
    desiredRole: 'USER',
    enabled: true,
  };

  const [newUser, setNewUser] = useState(newUserDefault);

  const [password2, setPassword2] = useState('');

  useEffect(() => {
    setLeftContainer(createUserView);

    updateUsersList();

    let intervalId = setInterval(() => {
      // console.log("updateUsersList")
      updateUsersList();
    }, 10000);

    return () => {
      clearInterval(intervalId);
      // console.log("clear updateUsersList")
    };
  }, []);

  useEffect(() => {
    if (selectedUserId === -1) {
      if (usersList !== undefined && usersList !== null && usersList.length !== 0 && usersList[0].id !== undefined)
        setSelectedUserId(usersList[0].id);
    }

    filterUsers();
  }, [usersList]);

  useEffect(() => {
    filterUsers();
  }, [filterName, filterCompanyId, filterNoKeyManager, filterRole, filterKeyManager]);

  useEffect(() => {
    setShowCreateUserView(false);

    if (selectedUserId !== -1) getUserInfo();
  }, [selectedUserId]);

  useEffect(() => {
    console.log(selectedUserInfo);
    if (selectedUserInfo === null || selectedUserInfo === undefined) {
      return;
    }

    setUserEnable(selectedUserInfo.enabled);
    setUserRole(selectedUserInfo.roleEntity.name);
    setUserLogin(selectedUserInfo.login);
    setUserEmail(selectedUserInfo.email);
    setUserName(selectedUserInfo.fullName);
    setUserKeyManager(selectedUserInfo.keyManagerId);
    setUserCompanyId(selectedUserInfo.shopId);
    setUserCompany(selectedUserInfo.companyName);
    setUserAddress(selectedUserInfo.address);
    setUserCertainAddress(selectedUserInfo.certainPlaceAddress);
    setUserPhone(selectedUserInfo.phoneNumber);

    setUserAppAccess(selectedUserInfo.appAccess);
  }, [selectedUserInfo]);

  useEffect(() => {
    // let company = ""
    allCompanies.forEach(item => {
      if (item.value != 0 && item.value == userCompanyId) {
        // company = item.name
        setUserCompany(item.name);
      }
    });
    // if(company !== "")
  }, [userCompanyId]);

  const updateUsersList = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };

    let url = '';

    if (user.role === 'ROLE_KEYMANAGER') {
      url = Endpoints.API.ALL_KEYMANAGER_USERS;
    } else if (user.role === 'ROLE_SHOP') {
      url = Endpoints.API.ALL_SHOP_USERS;
    }

    axios
      .get(url, config)
      .then(res => {
        let data = res.data;

        console.log(data);
        setUsersList(data);

        // let noKeyManager = 0;
        // let set = new Set();
        // let arrShops = []
        // let arrKeyManagers = []
        // data.forEach((user) =>{

        //     if(arrShops.indexOf(user.companyName) == -1)
        //     if(user. === "ROLE_SHOP"){
        //         // set.add(user.companyName)
        //         arrShops.push(user)
        //     }

        //     if(user.roleEntity.name === "ROLE_KEYMANAGER"){
        //         arrKeyManagers.push(user)
        //     }

        //     if(user.keyManagerId === 0 && user.roleEntity.id > 2){
        //         //user.roleEntity.id > 2 mean that Admin and Moderator role not include in this list
        //         noKeyManager++;
        //     }
        // })

        // setNoKeyManagerCounter(noKeyManager)

        // let keyManagers = [{value:0, name:"Не выбран"}]
        // for(let i =0;i< arrKeyManagers.length;i++){
        //     keyManagers.push({value:arrKeyManagers[i].id, name:arrKeyManagers[i].fullName})
        // }
        // setAllKeyManagers(keyManagers)

        // let companies = [{value:0, name:"Не выбран"}]
        // for(let i =0;i< arrShops.length;i++){
        //     companies.push({value:arrShops[i].id, name:arrShops[i].companyName + "(" + arrShops[i].fullName + ")"})
        // }
        // setAllCompanies(companies)
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getUserInfo = () => {
    // console.log("SHOW USER IFO: " + id)

    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };
    axios
      .get(Endpoints.API.USER_INFO + selectedUserId, config)
      .then(res => {
        setSelectedUserInfo(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const saveUserChanges = e => {
    // let userAppAccess = (appAccessK? "++k++":"") + (appAccessKM? "++km++":"") + (appAccessZ? "++z++":"") + (appAccessPM? "++pm++":"")

    // userAppAccess = userAppAccess.replaceAll("++++", ",")
    // userAppAccess = userAppAccess.replaceAll("++", "")

    console.log(userKeyManager);
    let newUsersData = {
      userId: selectedUserId,
      login: userLogin,
      email: userEmail,
      fullName: userName,
      companyName: userCompany,
      phoneNumber: userPhone,
      address: userAddress,
      certainPlaceAddress: userCertainAddress,
      appAccess: null,
      newRole: null,
      enabled: null,
      keyManager: null,
      shopId: null,
      // "shopId": userShopId
    };

    console.log(newUsersData);

    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json;charset=utf-8',
      },
    };

    axios
      .put(Endpoints.API.SHOP_EMPLOYEE_EDIT, JSON.stringify(newUsersData), config)
      .then(res => {
        showMessage('editUserMessage', 'Пользователь успешно изменен', '#4F5F56');
      })
      .catch(error => {
        if (error.response.status === 409) {
          showMessage('editUserMessage', 'Пользователь с таким login или email уже существует', 'red');
        } else {
          showMessage('editUserMessage', 'Не удалось изменить пользователя', 'red');
        }
        console.log(error);
      });
  };

  const validatePassword = e => {
    let passwordField1 = document.getElementById('passwordField1');
    let passwordField2 = document.getElementById('passwordField2');

    if (e.target.id === 'passwordField1') setNewUser(newUser => ({ ...newUser, ...{ password: e.target.value } }));
    else if (e.target.id === 'passwordField2') setPassword2(e.target.value);

    if (passwordField1.value !== passwordField2.value) {
      passwordField1.style.color = 'red';
      passwordField2.style.color = 'red';
    } else {
      passwordField1.style.color = 'black';
      passwordField2.style.color = 'black';
    }
  };

  const createNewUser = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json;charset=utf-8',
      },
    };

    newUser.desiredRole = 'USER';
    newUser.companyName = '';
    newUser.appAccess = 'k';
    // console.log(newUser)
    axios
      .put(Endpoints.API.SHOP_CREATE_EMPLOYEE, JSON.stringify(newUser), config)
      .then(res => {
        showMessage('createUserMessage', 'Пользователь успешно создан', '#4F5F56');

        setNewUser(newUserDefault);
        setPassword2('');
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 'ERR_BAD_REQUEST') {
          showMessage('createUserMessage', 'Пользователь с таким login или email уже существует', 'red');
        } else {
          showMessage('createUserMessage', 'Не удалось создать пользователя', 'red');
        }
      });
  };

  const showMessage = (elementId, message, color) => {
    let element = document.getElementById(elementId);
    element.innerText = message;
    element.style.color = color;
    setTimeout(() => {
      document.getElementById(elementId).innerText = '';
    }, 3000);
  };

  const filterUsers = () => {
    setFilteredUsers(
      usersList.filter(
        user =>
          (user.shopId == filterCompanyId || filterCompanyId == 0) &&
          (user.fullName.toLowerCase().indexOf(filterName.toLowerCase()) == 0 || filterName === '') &&
          ((user.keyManagerId == 0 && user.roleEntity.id > 2) || filterNoKeyManager === false) &&
          (user.roleEntity.name === filterRole || filterRole == '') &&
          (user.keyManagerId == filterKeyManager || filterKeyManager == 0),
      ),
    );

    console.log(filterNoKeyManager);
  };

  const editUserView = () => {
    return (
      <div className={style.info_container}>
        <div className={style.header}> Редактирование пользователя</div>

        <div className={style.user_logo}>
          <img src={userLogoImg} alt="" />
          <div>
            <p>Фото пользователя</p>
            <button>Выбрерите файл</button>
          </div>
        </div>

        <div className={style.info_body}>
          <div className="">
            <form
              id="edit_form"
              className={style.edit_form}
              method="post"
              onSubmit={e => {
                e.preventDefault();
                saveUserChanges();
              }}
            >
              {/* <div className={style.left}>
                            <div><label ><input type="checkbox" id="" name="enable" checked={userEnable} onChange={e=>{setUserEnable(e.target.checked)}}/> Активен </label></div>
                        </div> */}

              <div className="">
                <div className={style.user_data_container}>
                  {/* <div className="center">
                                    <div className="field_container">
                                        <p>Тип учетной записи:</p>
                                        <Select 
                                            data={[
                                                {value:"ROLE_ADMIN", name:"Администратор"},
                                                {value:"ROLE_MODERATOR", name:"Модератор"},
                                                {value:"ROLE_SUPPLIER", name:"Поставщик материала"},
                                                {value:"ROLE_KEYMANAGER", name:"Менеджер"},
                                                {value:"ROLE_SHOP", name:"Мебельный салон/Фабрика"},
                                                {value:"ROLE_USER", name:"Дизайнер/архитектор"},
                                            ]}
                                            value={userRole} 
                                            onChange={(e) => setUserRole(e.target.value)}>
                                        </Select>

                                    </div>
                                </div> */}

                  <div className="center">
                    <div className="field_container">
                      <p>Логин:</p>
                      <Input
                        disabled={disableEditingUsers}
                        pattern="[a-zA-Z0-9._]*"
                        title="Символы латиницы, цифры, . и _"
                        value={userLogin}
                        required
                        placeholder="Введите логин"
                        onChange={e => setUserLogin(e.target.value)}
                      ></Input>
                    </div>
                  </div>
                  <div className="center">
                    <div className="field_container">
                      <p>Email:</p>
                      <Input
                        disabled={disableEditingUsers}
                        type="email"
                        value={userEmail}
                        required
                        placeholder="Введите email"
                        onChange={e => setUserEmail(e.target.value)}
                      ></Input>
                    </div>
                  </div>
                  <div className="center">
                    <div className="field_container">
                      <p>ФИО:</p>
                      <Input
                        disabled={disableEditingUsers}
                        value={userName}
                        required
                        placeholder="ФИО"
                        onChange={e => setUserName(e.target.value)}
                      ></Input>
                    </div>
                  </div>
                </div>
                <div className={style.user_data_container}>
                  {/* <div className="center">
                                    <div className="field_container">
                                        <p>Менеджер для этого пользователя:</p>
                                        <Select 
                                            data={allKeyManagers} 
                                            value={userKeyManager} 
                                            onChange={e => {setUserKeyManager(e.target.value)}}>
                                        </Select>
                                    </div>
                                </div>


                                <div className="center">
                                    <div className="field_container">
                                        <p>Компания (пользователь):</p>
                                        <Select 
                                            data={allCompanies} 
                                            value={userCompanyId} 
                                            onChange={e => {setUserCompanyId(e.target.value);}}>
                                        </Select>
                                    </div>
                                </div> */}

                  <div className="center" hidden>
                    <div className="field_container">
                      <p>Адрес компании:</p>
                      <Input
                        disabled={disableEditingUsers}
                        value={userAddress}
                        placeholder="Адрес"
                        onChange={e => setUserAddress(e.target.value)}
                      ></Input>
                    </div>
                  </div>

                  <div className="center">
                    <div className="field_container">
                      <p>Компания (имя):</p>
                      <Input
                        disabled={disableEditingUsers}
                        value={userCompany}
                        required
                        placeholder="Компания"
                        onChange={e => setUserCompany(e.target.value)}
                      ></Input>
                    </div>
                  </div>
                  <div className="center">
                    <div className="field_container">
                      <p>Адрес местонахождения:</p>
                      <Input
                        disabled={disableEditingUsers}
                        value={userCertainAddress}
                        placeholder="Адрес местонахождения"
                        onChange={e => setUserCertainAddress(e.target.value)}
                      ></Input>
                    </div>
                  </div>
                  <div className="center">
                    <div className="field_container">
                      <p>Номер телефона:</p>
                      <Input
                        disabled={disableEditingUsers}
                        value={userPhone}
                        placeholder="Номер телефона"
                        onChange={e => setUserPhone(e.target.value)}
                      ></Input>
                    </div>
                  </div>
                  {/* <div className="center">
                                    <div className="field_container">
                                        <p>Доступные приложения:</p>
                                        <AppSelector apps={userAppAccess} onChange={value => setUserAppAccess(value)}></AppSelector>
                                    </div>
                                </div> */}
                </div>
              </div>
              {!disableEditingUsers ? (
                <div>
                  <input
                    type="button"
                    style={{ width: 200, marginLeft: 20 }}
                    className={style.btnCreateUser}
                    value="Отменить"
                    onClick={e => {
                      getUserInfo();
                    }}
                  />
                  <input
                    id="btnSave"
                    style={{ width: 200, marginRight: 20 }}
                    className={style.btnCreateUser}
                    type="submit"
                    value="Сохранить"
                  />
                </div>
              ) : (
                <></>
              )}
            </form>
            <div id="editUserMessage" style={{ textAlign: 'center' }}></div>
          </div>
        </div>
      </div>
    );
  };

  const createUserView = () => {
    return (
      <div className={style.create_user}>
        <div className={style.header}> Создание нового пользователя</div>

        <div className={style.info_body}>
          <div className="">
            <form
              id="edit_form"
              className={style.edit_form}
              onSubmit={e => {
                e.preventDefault();
                createNewUser();
              }}
            >
              <div className="">
                <div className={style.user_data_container}>
                  {/* <div className="center">
                                    <div className="field_container" style={{display:"hidden"}}>
                                        <p>Тип учетной записи:</p>
                                        <Select 
                                            data={[
                                                {value:"SUPPLIER", name:"Поставщик материала"},
                                                {value:"SHOP", name:"Салон"},
                                                {value:"USER", name:"Дизайнер"}
                                            ]}
                                            value={newUser.desiredRole} 
                                            onChange={(e) => setNewUser(newUser => ({...newUser, ...{desiredRole: e.target.value}}))}>
                                        </Select>
                                    </div>
                                </div> */}

                  <div className="center">
                    <div className="field_container">
                      <p>Логин:</p>
                      <Input
                        pattern="[a-zA-Z0-9._]*"
                        value={newUser.login}
                        required
                        placeholder="Введите логин"
                        onChange={e => setNewUser(newUser => ({ ...newUser, ...{ login: e.target.value } }))}
                      ></Input>
                    </div>
                  </div>
                  <div className="center">
                    <div className="field_container">
                      <p>Email:</p>
                      <Input
                        type="Email"
                        value={newUser.email}
                        required
                        placeholder="Введите email"
                        onChange={e => setNewUser(newUser => ({ ...newUser, ...{ email: e.target.value } }))}
                      ></Input>
                    </div>
                  </div>

                  <div className="center">
                    <div className="field_container">
                      <p>Пароль:</p>
                      <Input
                        id="passwordField1"
                        type="password"
                        required
                        value={newUser.password}
                        placeholder="Введите пароль"
                        autoComplete="new-password"
                        onChange={e => {
                          validatePassword(e);
                        }}
                      ></Input>
                    </div>
                  </div>
                  <div className="center">
                    <div className="field_container">
                      <p>Повторите пароль:</p>
                      <Input
                        id="passwordField2"
                        type="password"
                        required
                        value={password2}
                        placeholder="Повторите пароль"
                        autoComplete="new-password"
                        onChange={e => {
                          validatePassword(e);
                        }}
                      ></Input>
                    </div>
                  </div>
                </div>
                <div className={style.user_data_container}>
                  <div className="center">
                    <div className="field_container">
                      <p>ФИО:</p>
                      <Input
                        required
                        value={newUser.fullName}
                        placeholder="Введите ФИО"
                        onChange={e => setNewUser(newUser => ({ ...newUser, ...{ fullName: e.target.value } }))}
                      ></Input>
                    </div>
                  </div>

                  {/* <div className="center">
                                    <div className="field_container">
                                        <p>Компания:</p>
                                        <Input 
                                            value={newUser.companyName} 
                                            required 
                                            placeholder="Введите или выберите" 
                                            onChange={e => setNewUser(newUser => ({...newUser, ...{companyName:e.target.value}}))}>
                                        </Input>
                                    </div>
                                </div> */}

                  {/* <div className="center">
                                    <div className="field_container">
                                        <p>Или выберите из имеющихся:</p>
                                        <Select 
                                            data={allCompanies} 
                                            onChange={e => setNewUser(newUser => ({...newUser, ...{companyName:e.target.value}}))}>
                                        </Select>
                                    </div>
                                </div> */}

                  <div className="center" hidden>
                    <div className="field_container">
                      <p>Адрес компании:</p>
                      <Input
                        value={newUser.address}
                        placeholder="Адрес компании"
                        onChange={e => setNewUser(newUser => ({ ...newUser, ...{ address: e.target.value } }))}
                      ></Input>
                    </div>
                  </div>
                  <div className="center">
                    <div className="field_container">
                      <p>Адрес местонахождения:</p>
                      <Input
                        value={newUser.certainPlaceAddress}
                        placeholder="Адрес местонахождения"
                        onChange={e =>
                          setNewUser(newUser => ({ ...newUser, ...{ certainPlaceAddress: e.target.value } }))
                        }
                      ></Input>
                    </div>
                  </div>
                  <div className="center">
                    <div className="field_container">
                      <p>Номер телефона:</p>
                      <Input
                        value={newUser.phoneNumber}
                        placeholder="Номер телефона"
                        onChange={e => setNewUser(newUser => ({ ...newUser, ...{ phoneNumber: e.target.value } }))}
                      ></Input>
                    </div>
                  </div>
                  {/* <div className="center">
                                    <div className="field_container">
                                        <p>Доступные приложения:</p>
                                        <AppSelector apps={newUser.appAccess} onChange={value => setNewUser(appAccess => ({...newUser, ...{appAccess:value}}))}></AppSelector>

                                    </div>
                                </div> */}
                </div>
              </div>

              <input className={style.btnCreateUser} type="submit" value="Создать пользователя" />
            </form>
            <div id="createUserMessage" style={{ textAlign: 'center' }}></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={style.container_external}>
        <div className={style.container_mid}>
          {showCreateUserView ? createUserView() : editUserView()}

          <div className={style.list_container}>
            <div className={style.user_filter}>
              {user.role !== 'ROLE_KEYMANAGER' ? (
                <button
                  className={style.icon_button + ' ' + style.add_user_button}
                  onClick={e => {
                    setShowCreateUserView(!showCreateUserView);
                  }}
                >
                  {showCreateUserView ? 'Назад' : 'Добавить пользователя'}
                </button>
              ) : (
                <></>
              )}

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <span>Фильтровать:</span>
              </div>
              {/* <div>
                                <label><input type="checkbox" id="nokeymanager" checked={filterNoKeyManager} onChange={e =>setfilterNoKeyManager(e.target.checked)}/> Требуют модерации - ({noKeyManagerCounter})</label>
                            </div> */}
              <div>
                <span>По имени:</span>
                <input
                  type="text"
                  name=""
                  id="input_full_name"
                  value={filterName}
                  onChange={e => setFilterName(e.target.value)}
                />
              </div>

              {/* {user.role === "ROLE_KEYMANAGER"?<div>
                                <span>Салон:</span>
                                <select   
                                    value={filterCompanyId} 
                                    onChange={(e) => setFilterCompanyId(e.target.value)}>
                                        {allCompanies.map((item) =>(<option key={item.value} value={item.value}>{item.name}</option>))} 
                                </select>
                            </div>:<></>} */}
              {/* <div>
                                <span>Менеджер:</span>
                                <select   
                                    value={filterKeyManager} 
                                    onChange={(e) => {console.log(e.target.value);setFilterKeyManager(e.target.value)}}>
                                        {allKeyManagers.map((item) =>(<option key={item.value} value={item.value}>{item.name}</option>))} 
                                </select>
                            </div> */}
              {user.role === 'ROLE_KEYMANAGER' ? (
                <div>
                  <span>По роли:</span>
                  <select value={filterRole} onChange={e => setFilterRole(e.target.value)}>
                    <option value="">Все</option>
                    <option value="ROLE_SUPPLIER">Поставщик материала</option>
                    <option value="ROLE_SHOP">Мебельный салон/Фабрика</option>
                    <option value="ROLE_USER">Дизайнер/архитектор</option>
                  </select>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className={style.users_list}>
              <ul>
                {filteredUsers.map(user => (
                  <li key={user.id}>
                    <div
                      className={
                        selectedUserId === user.id ? style.user_row + ' ' + style.user_row_selected : style.user_row
                      }
                      onClick={() => {
                        setSelectedUserId(user.id);
                      }}
                    >
                      <img src={userLogoImg} alt="" />
                      <div className={style.user_info}>
                        <p className={style.user_name}>{user.login}</p>
                        <p className={style.user_name}>{user.fullName}</p>
                        <p className={style.user_company}>{user.companyName}</p>
                        <span id="id" hidden>
                          id:{user.id}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyUsersPage;
