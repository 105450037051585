import { React, useEffect, useState } from 'react';
import style from '../css/dashboard.module.css';
import { ACCESS_TOKEN_NAME } from '../constants';
import { Endpoints } from '../api/endpoints';
import axios from 'axios';

import { LineChart, XAxis, YAxis, CartesianGrid, Line, Legend, Tooltip } from 'recharts';
// import { LChart } from "./charts/lineChart";
import ReactDatePicker from 'react-datepicker';

const Dashboard = () => {
  const [activities, setActivities] = useState();
  const [startTime, setStartTime] = useState();
  const [workTime, setWorkTime] = useState();
  const [usesMemory, setUsesMemoty] = useState();

  const [usersOnline, setUsersOnline] = useState();
  const [usersOffline, setUsersOffline] = useState();
  const [usersNumber, setUsersNumber] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [chartPrecession, setChartPrecession] = useState(10);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    chartsSetMonth();

    updateUserActivities();
    updateStartTime();
    updateWorkTime();
    updateMemoryInfo();
    updateUsersNumber();
    updateUsersOnline();
    // updateUsersStatistics();

    let intervalId = setInterval(() => {
      // console.log("updateEvents")
      updateUserActivities();
      updateStartTime();
      updateWorkTime();
      updateMemoryInfo();
      updateUsersNumber();
      updateUsersOnline();
      // updateUsersStatistics()
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (usersNumber !== undefined && usersOnline !== undefined) {
      setUsersOffline(usersNumber - usersOnline);
    }
  }, [usersNumber, usersOnline]);

  useEffect(() => {
    updateUsersStatistics();
  }, [startDate, endDate, chartPrecession]);

  const updateUserActivities = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    // console.log(loginData)

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };
    axios
      .get(Endpoints.API.USER_ACIVITIES, config)
      .then(res => {
        setActivities(
          res.data.map((row, index) => (
            <tr key={index}>
              <td>{new Date(row.activityTime).toLocaleDateString()}</td>
              <td>{row.userLogin}</td>
              <td>{row.activityType}</td>
              <td>{row.activityMessage}</td>
            </tr>
          )),
        );
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateStartTime = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };
    axios
      .get(Endpoints.API.APP_START_TIME, config)
      .then(res => {
        setStartTime(res.data);
        // console.log(res.data)
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateWorkTime = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };
    axios
      .get(Endpoints.API.APP_WORK_TIME, config)
      .then(res => {
        setWorkTime(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateMemoryInfo = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };
    axios
      .get(Endpoints.API.APP_MEM_INFO, config)
      .then(res => {
        setUsesMemoty(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateUsersNumber = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };
    axios
      .get(Endpoints.API.ALL_USERS, config)
      .then(res => {
        setUsersNumber(res.data.length);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateUsersOnline = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };
    axios
      .get(Endpoints.API.USERS_STATS, config)
      .then(res => {
        setUsersOnline(res.data.length);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateUsersStatistics = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

    // let startPeriod = new Date(startDate)
    // let endPeriod = new Date(endDate)

    let data = {
      type: 'onlineUsers,newUsers,receipts',
      startDateTime: startDate.toISOString(),
      endDateTime: endDate.toISOString(),
      precession: chartPrecession,
    };

    // console.log(data)

    const config = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json;charset=utf-8',
      },
    };

    axios
      .post(Endpoints.API.USER_STATISTICS, JSON.stringify(data), config)
      .then(res => {
        console.log(data);
        console.log(res.data);
        // console.log(res.data.endDateTime)
        // console.log(res.data.newReceipts)
        // console.log(res.data.newUsers)
        // console.log(res.data.usersOnline)

        let startDate = new Date(res.data.startDateTime);
        let endDate = new Date(res.data.endDateTime);

        let chartD = [];

        let period = (endDate.getTime() - startDate.getTime()) / res.data.newReceipts.length;
        // console.log(period)
        // console.log(endDate.getTime() - startDate.getTime())
        // console.log(res.data.newReceipts.length)

        let t1 = startDate.getTime();
        let t2 = t1 + period;
        for (let i = 0; i < res.data.newReceipts.length; i++) {
          let time1 = new Date();
          let time2 = new Date();
          time1.setTime(t1);
          time2.setTime(t2);
          chartD[i] = {
            newReceipts: res.data.newReceipts[i],
            newUsers: res.data.newUsers[i],
            usersOnline: res.data.usersOnline[i],
            time: time1.toLocaleDateString() + ' - ' + time2.toLocaleDateString(),
          };

          t1 = t2;
          t2 = t2 + period;
        }
        setChartData(chartD);
        // setUserStatistics(chartData)

        // console.log(chartData)

        // console.log(startDate)
        // console.log(endDate)
      })
      .catch(error => {
        console.log(error);
      });
  };

  const chartsSetWeek = () => {
    let date2 = new Date();
    let date1 = new Date();
    date1.setDate(date2.getDate() - 7);

    setStartDate(date1);
    setEndDate(date2);
  };

  const chartsSetMonth = () => {
    let date2 = new Date();
    let date1 = new Date();
    date1.setMonth(date2.getMonth() == 0 ? 11 : date2.getMonth() - 1);

    setStartDate(date1);
    setEndDate(date2);
  };

  const chartsSetYear = () => {
    let date2 = new Date();
    let date1 = new Date();
    date1.setFullYear(date2.getUTCFullYear() - 1);
    console.log(date2.getUTCFullYear());
    console.log(date2.getFullYear());

    setStartDate(date1);
    setEndDate(date2);
  };

  return (
    <>
      <div className={style.system_info_container_external}>
        <div className={style.system_info_container}>
          <div className={style.system_info_element}>
            <p className={style.info_header}> О системе</p>
            <div className={style.sys_start_date}>
              <p>Дата запуска:</p>
              <p>{startTime}</p>
            </div>
            <div className={style.sys_work_time}>
              <p>Время работы сервера:</p>
              <p>{workTime}</p>
            </div>
            <div className={style.sys_memory}>
              <p>Память:</p>
              <p>{usesMemory}</p>
            </div>
          </div>

          <div className="users_info_element">
            <p className="info_header"> О пользователях</p>
            <div className="users_all">
              <p>Всего :</p>
              <p>{usersNumber}</p>
            </div>
            <div className="users_online">
              <p>Онлайн:</p>
              <p>{usersOnline}</p>
            </div>
            <div className="users_offline">
              <p>Оффлайн:</p>
              <p>{usersOffline}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={style.container}>
        <div className={style.container_mid}>
          <div>
            <div className={style.chart_buttons}>
              <input
                type="button"
                value="неделя"
                onClick={e => {
                  chartsSetWeek();
                }}
              />
              <input
                type="button"
                value="месяц"
                onClick={e => {
                  chartsSetMonth();
                }}
              />
              <input
                type="button"
                value="год"
                onClick={e => {
                  chartsSetYear();
                }}
              />
            </div>

            <div className={style.chartDates}>
              <div>
                <ReactDatePicker
                  className={style.datepicker}
                  id="dateFrom"
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={date => {
                    if (endDate >= date) setStartDate(date);
                  }}
                />
              </div>
              <span>-</span>
              <div>
                <ReactDatePicker
                  className={style.datepicker}
                  id="dateTo"
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={date => {
                    if (date >= startDate) setEndDate(date);
                  }}
                />
              </div>

              <span> Точность:</span>
              <div>
                <select
                  value={chartPrecession}
                  onChange={e => {
                    setChartPrecession(e.target.value);
                  }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
          </div>
          <div className={style.charts}>
            <LineChart width={600} height={250} data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" interval="preserveStart" tickSize={10} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="newReceipts" name="Расчеты" stroke="#8884d8" />
            </LineChart>

            <LineChart width={600} height={250} data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="usersOnline" name="Пользователи в сети" stroke="#82ca9d" />
              <Line type="monotone" dataKey="newUsers" name="Новые пользователи" stroke="#92ab9d" />
            </LineChart>
          </div>
        </div>
      </div>

      <div className={style.container}>
        <div className={style.container_mid}>
          <div className={style.events}>
            <div className={style.table_caption}>
              <h4>События портала</h4>
            </div>

            <div className={style.table_container}>
              <table>
                <colgroup>
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '40%' }} />
                </colgroup>

                <thead>
                  <tr>
                    <th>Дата</th>
                    <th>Пользователь</th>
                    <th>Операция</th>
                    <th>Дополнительно</th>
                  </tr>
                </thead>

                <tbody>{activities}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
