import React from 'react';
import classes from './Select.module.css';

const Select = ({ data, ...props }) => {
  // console.log(data)

  return (
    <select className={classes.Select} {...props}>
      {data.map(item => (
        <option key={item.value} value={item.value}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

export default Select;
