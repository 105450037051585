export const URL_LOGIN = '/login';

export const URL_API_AUTH = '/auth';
export const URL_API_REGISTER = '/register';
export const URL_API_ME = '/me';
export const URL_API_UPDATE_TOKEN = '/token';
export const URL_API_UPDATE_REFRESH_TOKEN = '/api/refreshToken';
export const URL_API_CHANGE_PASSSWORD = '/api/changeOwnPassword';

export const URL_UPDATES_GET_LIST = '/api/updatefiles/allFiles';
export const URL_UPDATES_UPLOAD_FILE = '/api/updatefiles/uploadFile';
export const URL_UPDATES_DELETE_FILE = '/api/updatefiles/deleteFile/';
export const URL_UPDATES_DOWNLOAD_FILE = '/api/updatefiles/downloadFile/';
export const URL_UPDATES_EDIT_FILE = '/api/updatefiles/editFileInfo/';

export const URL_PRICES_GET_LIST = '/api/pricelists/allFiles';
export const URL_PRICES_UPLOAD_FILE = '/api/pricelists/uploadFile';
export const URL_PRICES_DELETE_FILE = '/api/pricelists/deleteFile/';
export const URL_PRICES_DOWNLOAD_FILE = '/api/pricelists/downloadFile/';
export const URL_PRICES_EDIT_FILE = '/api/pricelists/editFileInfo/';

export const URL_GET_CALC_ACTIVITY_ALL = '/api/app/allCalcActivities';
export const URL_GET_CALC_ACTIVITY_FILTERED = '/api/app/calcActivityFilter';
export const URL_GET_CALC_ACTIVITY_FILTERED_XLS = '/api/app/calcActivityFilterFile';
export const URL_GET_CALC_ACTIVITY_DOWNLOAD_XLS = '/api/app/downloadFile/'; // + {fileName}

export const URL_GET_ALL_USERS = '/api/users/getAllUsers';
export const URL_GET_USER = '/api/users/getUser/'; //+{id}
export const URL_GET_EDIT_USER = '/api/users/editUser';

export const URL_GET_USER_OWN_INFO = '/api/getUserInfo';
export const URL_USER_OWN_EDIT = '/api/editOwnInfo';

export const URL_CHANGE_USER_PW = '/api/changeOwnPassword';

export const ACCESS_TOKEN_NAME = 'accessToken';
export const REFRESH_TOKEN_NAME = 'refreshToken';

export const APP_ZETTA = 'z';
export const APP_PRO_MEBEL = 'pm';
export const APP_KOREANIKA = 'k';
export const APP_KOREANIKA_MASTER = 'km';
export const APP_ALL = 'all';
